import { defineNuxtPlugin, useRouter, useRuntimeConfig } from "#app";
import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";

export default defineNuxtPlugin(() => {
  const router = useRouter();
  const { platform } = useRuntimeConfig().public;

  if (platform === "mobile") {
    App.addListener("appUrlOpen", async function (event) {
      const url = new URL(event.url);

      try {
        if (Browser) {
          await Browser.close();
        }

        const queryParams = Object.fromEntries(url.searchParams);

        await router.push({
          path: url.pathname,
          ...(Object.keys(queryParams).length && { query: queryParams }),
        });
      } catch (error) {
        console.error("Error during navigation:", error);
      }
    });
  }
});
