import { App } from "@capacitor/app";

export default defineNuxtPlugin(async (nuxtApp) => {
  try {
    const info = await App.getInfo();
    nuxtApp.provide("appVersion", info.version);
  } catch (e) {
    // Fallback to web version
    const info = { version: "web" };
    nuxtApp.provide("appVersion", info.version);
  }
});
